import React from 'react'
import styles from './styles/about.module.scss'
import Layout from "../components/Layout"
import {navigate} from 'gatsby'
import { Button } from '@material-ui/core'
import PartnerSection from "../components/PartnerSection"
import FreeTour from './../components/FreeTour'
import SEO from "../components/seo"


const About = () => {
	return(
		<Layout>
			<SEO title="About" />
			<img alt="galeria" className={styles.mainImage} src={require('./../images/sobrenosotros.jpg')}/>
			<div className={styles.secondSection}>
				<h2> Creemos en espacios flexibles, <br/> adaptables a tu equipo y flujo de trabajo </h2>
				<div className={styles.descriptionText}>
					<p>
						Un espacio que impulsa el crecimiento empresarial por medio de espacios abiertos con estilo moderno en el cual varias empresas y emprendedores trabajan compartiendo espacio e ideas.
						Cuenta con espacios personales de trabajo y oficinas privadas además de dos salas de juntas y o un auditorio para 80 personas.
					</p>
					<p>
						Ofrecer a nuestros clientes un espacio de Co-working donde podrán empoderar sus proyectos y hacer realidad sus sueños en la ciudad de Barranquilla,
						trabajando entre todos, con los mas altos estándares de calidad y servicios que ofreceremos.
					</p>
				</div>
			</div>
			{/* <div className={styles.thirdSection}>
				 <h2> Conoce Nuestro Equipo </h2>
				<p>Cowork entre todos comenzó ayudando a las personas a construir proyectos impresionantes.</p>
				<p>Cada día nuestro equipo continúa creciendo más creadores en el mundo para hacer eso.</p>
				<div className={styles.teamPhotos}>
					<div className={styles.personBox}>
						<img alt="imagen_galeria" src={require('./../images/coworker1.png')}/>
						<div className={styles.dataContainer}>
							<h3>Jhon Doe</h3>
							<p>Manager</p>
						</div>
					</div>
					<div className={styles.personBox}>
						<img alt="imagen_galeria" src={require('./../images/coworker2.png')}/>
						<div className={styles.dataContainer}>
							<h3>Susan Albert</h3>
							<p>Diseñador</p>
						</div>
					</div>
					<div className={styles.personBox}>
						<img alt="imagen_galeria" src={require('./../images/coworker3.png')}/>
						<div className={styles.dataContainer}>
							<h3>Marco Trevino</h3>
							<p>Co-fundador</p>
						</div>
					</div>
					<div className={styles.personBox}>
						<img alt="imagen_galeria" src={require('./../images/coworker4.png')}/>
						<div className={styles.dataContainer}>
							<h3>Keneth Williams</h3>
							<p>Fundador</p>
						</div>
					</div>
				</div> 
				<Button variant="outlined" className={styles.buttonJoinTeam} onClick={()=>navigate('/contact')}>
					Unete al equipo
				</Button>
			</div> */}
			<div className={styles.fourthSection}>
				<div className={styles.miniSec}>
					<div>
						<h2>¿Buscas un espacio? Empieza aqui.</h2>
						<p>
							En cowork entre todos ofrecemos diferentes espacios con el find e potencializar el exito de las personas y equipos que necesitan un espacio.
						</p>
					</div>
					<div className={styles.buttonContainer}>
						<Button variant="contained" className={styles.buttonContained} onClick={()=>navigate('filter')}>
							VER ESPACIOS
						</Button>
					</div>
				</div>
				<div className={styles.miniSec}>
					<div>
						<h2>¿Quieres conocer nuestras instalaciones?</h2>
						<p>
							Ofrecemos un tour completamente gratuito para aquellos que deseen darnos una visita. ¡Te esperamos!
						</p>
					</div>
					<div className={styles.buttonContainer}>
						<Button variant="contained" className={styles.buttonContained} onClick={()=>navigate('/contact')}>
							AGENDA TOUR
						</Button>
					</div>
				</div>
			</div>
			<PartnerSection/>
			<FreeTour/>
		</Layout>
	)
}

export default About